import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import sagas from './sagas/index';
import reducer from './reducers/index';
import { TranslationProvider } from './context/TranslationContext';
import App from './components/App';
import { PropsProvider } from './context/PropsContext';
import { pkgName } from './appConstants';
import './utils/strings';

const Widget = ({ i18n, language, ...restProps }) => {
  const t = i18n
    ? (key) => {
        const tr = i18n(key);
        return tr || key;
      }
    : (key) => key;

  if (!language) return null;

  return (
    <TranslationProvider t={t}>
      <PropsProvider widgetProps={{ ...restProps }}>
        <App {...restProps} />
      </PropsProvider>
    </TranslationProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
});
export const ConnectedWidget = connect(mapStateToProps)(Widget);

const exported = ConnectedWidget;

Widget.propTypes = {
  i18n: PropTypes.any,
  language: PropTypes.string,
  __instanceId: PropTypes.string,
  __pageId: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,

  redirection: PropTypes.shape({
    prepaidService: PropTypes.bool.isRequired,
    postpaidService: PropTypes.bool.isRequired,
    serviceURL: PropTypes.string.isRequired,
  }).isRequired,

  visibility: PropTypes.shape({
    serviceLabel: PropTypes.bool.isRequired,
  }).isRequired,

  filters: PropTypes.shape({
    MinProductsNumber: PropTypes.number.isRequired,
    MultiSelect: PropTypes.bool.isRequired,
    defaultFilterIndex: PropTypes.number.isRequired,
    filtersArray: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
  }),

  maxCardsShouldAppear: PropTypes.number.isRequired,
};

const persistConfig = {
  key: pkgName,
  storage: storageSession,
};

exported.reducer = persistReducer(persistConfig, reducer);

exported.sagas = sagas;

export default exported;
