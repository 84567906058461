import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  GridRow,
  GridColumn,
  MatchMedia,
  Spacing,
  Paragraph,
  StateNotification,
} from 'r10-source-library';

import {
  sendTags,
  validateMSISDNNumber,
  updateCurrentScreen,
  updatePhoneNumberNotFoundStatus,
  getProps,
  updatePhoneNumber,
  updateTopUpValue,
  updateTopUpMinValue,
} from '../../actions/Actions';
import useTranslation from '../../hooks/useTranslation';
import {
  nextButtonIsDisabled,
  phoneNumberSelector,
  topUpValueSelector,
  phoneNumberNotFoundSelector,
  minTopUpValueSelector,
  propSelector,
  getTopupInfoSelector,
} from '../../selectors/selector';
import { SCREENS } from '../../appConstants';
import Amount, { isValidTopUpValue } from './Amount/Amount';
import NumberInput from './NumberInput/NumberInput';
import DropdownInput from './DropdownInput/DropdownInput';

const TopUp = ({
  validationRegex,
  currency,
  minTopUpValue,
  maxTopUpValue,
  step,
  commonTopUpValues,
  isNextButtonDisabled,
  phoneNumber,
  topUpValue,
  isFastTopupFlow,
  validateMSISDN,
  phoneNumberNotFound,
  updateCurrScreen,
  updatePhoneNumNotFoundStatus,
  taxPercentage,
  topupListInfo,
  updPhoneNumber,
  updateTopUpMinVal,
  topupMinValuePreUpdate,
  sendTags,
  topupInfo,
  emergencyTopupLoanConverted,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isFastTopupFlow)
      sendTags({
        taggingId: 'CUSTOMER_TOPUP_2',
        taggingType: 'view',
        taggingValue: {},
      });
  }, [isFastTopupFlow, sendTags]);

  useEffect(() => {
    if (phoneNumberNotFound !== null) setIsLoading(false);
  }, [phoneNumberNotFound]);
  useEffect(() => {
    updPhoneNumber(phoneNumber);
    sendTags({
      taggingId: 'CUSTOMER_TOPUP_3',
      taggingType: 'link',
      taggingValue: {
        event_value: topupInfo?.phoneNumber,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const defaultMinTopUpOfPlan = topupListInfo?.phoneNumbers?.find(
      elem => elem.phoneNumber === phoneNumber
    )?.minValue;
    updateTopUpMinVal(
      defaultMinTopUpOfPlan > topupMinValuePreUpdate
        ? defaultMinTopUpOfPlan
        : topupMinValuePreUpdate
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const t = useTranslation();

  const handleOnNext = useCallback(async () => {
    updatePhoneNumNotFoundStatus(null);
    if (isFastTopupFlow) {
      setIsLoading(true);
      validateMSISDN();
    } else updateCurrScreen(SCREENS.CONFIRM);

    sendTags({
      taggingId: 'CUSTOMER_TOPUP_5',
      taggingType: 'link',
      taggingValue: {},
    });
  }, [isFastTopupFlow, updateCurrScreen, updatePhoneNumNotFoundStatus, validateMSISDN, sendTags]);

  return (
    <>
      <GridRow justify="center" align="center" marginBottom={4}>
        <Paragraph size={2} weight={3} justify="center" noMargin>
          {t('headerTopUp')}
        </Paragraph>
      </GridRow>
      {emergencyTopupLoanConverted !== '0' && (
        <GridRow>
          <GridColumn>
            <StateNotification state="warn">
              <Paragraph
                dataAttributes={{
                  'data-testid': 'emergency-topup-loan-warning',
                }}
              >
                {`${t('emergencyTopupLoanPrefix')}${emergencyTopupLoanConverted}${currency}${t(
                  'emergencyTopupLoanSuffix'
                )}`}
              </Paragraph>
            </StateNotification>
          </GridColumn>
        </GridRow>
      )}
      <GridRow justify="center" align="center" marginBottom={2} noGutters>
        <GridColumn>
          <MatchMedia breakpoint="md" andAbove>
            <Spacing
              spacingLevel={{
                top: 6,
              }}
            ></Spacing>
          </MatchMedia>
          {isFastTopupFlow ? (
            <NumberInput validationRegex={validationRegex} disable={isLoading} />
          ) : (
            <DropdownInput
              phoneNumber={topupListInfo.phoneNumber}
              phoneNumbers={topupListInfo.phoneNumbers}
            />
          )}
        </GridColumn>
      </GridRow>
      <GridRow justify="center" align="center" marginBottom={2} noGutters>
        <GridColumn>
          <Amount
            currency={currency}
            minValue={minTopUpValue}
            maxValue={maxTopUpValue}
            step={step}
            commonTopUpValues={commonTopUpValues}
            isFastTopupFlow={isFastTopupFlow}
            disableButtons={isLoading}
          />
        </GridColumn>
      </GridRow>
      <GridRow justify="center" align="center" noGutters marginBottom={2}>
        <GridColumn>
          <Paragraph
            size={2}
            weight={2}
            justify="center"
            dataAttributes={{ 'data-testid': 'taxMessage' }}
          >
            {t('taxMessage', { taxPercentage })}
          </Paragraph>
          <Paragraph
            size={2}
            weight={3}
            justify="center"
            noMargin
            dataAttributes={{ 'data-testid': 'netAmount' }}
          >
            {t('netAmount')}: {((1 / ((100 + taxPercentage) / 100)) * topUpValue).toFixed(2)}
            {currency}
          </Paragraph>
        </GridColumn>
      </GridRow>
      <GridRow justify="center" align="center" noGutters>
        <GridColumn col={10} colMd={6}>
          <Button
            width="full"
            loading={isLoading}
            state={
              isNextButtonDisabled ||
              !phoneNumber.match(validationRegex) ||
              !isValidTopUpValue(topUpValue, minTopUpValue, maxTopUpValue, step) ||
              isLoading
                ? 'disabled'
                : ''
            }
            text={t('bodyTopupNext')}
            appearance="primary"
            onClick={handleOnNext}
            dataAttributes={{
              'data-testid': 'next-topup-screen',
            }}
          />
        </GridColumn>
      </GridRow>
    </>
  );
};
const mapStateToProps = state => ({
  isNextButtonDisabled: nextButtonIsDisabled(state),
  phoneNumber: phoneNumberSelector(state),
  topUpValue: topUpValueSelector(state),
  minTopUpValue: minTopUpValueSelector(state),
  phoneNumberNotFound: phoneNumberNotFoundSelector(state),
  taxPercentage: propSelector(state, 'tax'),
  topupInfo: getTopupInfoSelector(state),
});
const mapDispatchToProps = dispatch => ({
  getProps: () => dispatch(getProps()),
  validateMSISDN: () => dispatch(validateMSISDNNumber()),
  updateCurrScreen: value => dispatch(updateCurrentScreen(value)),
  updatePhoneNumNotFoundStatus: value => dispatch(updatePhoneNumberNotFoundStatus(value)),
  updateTopUpVal: value => dispatch(updateTopUpValue(value)),
  updPhoneNumber: newPhoneNumber => dispatch(updatePhoneNumber(newPhoneNumber)),
  updateTopUpMinVal: value => dispatch(updateTopUpMinValue(value)),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
TopUp.propTypes = {
  validationRegex: PropTypes.any,
  currency: PropTypes.string,
  minTopUpValue: PropTypes.number,
  maxTopUpValue: PropTypes.number,
  step: PropTypes.number,
  commonTopUpValues: PropTypes.array,
  validateMSISDN: PropTypes.func,
  updateCurrScreen: PropTypes.func,
  updatePhoneNumNotFoundStatus: PropTypes.func,
  isNextButtonDisabled: PropTypes.bool,
  phoneNumberNotFound: PropTypes.bool,
  phoneNumber: PropTypes.string,
  phoneNumbers: PropTypes.array,
  topUpValue: PropTypes.number,
  isFastTopupFlow: PropTypes.bool,
  taxPercentage: PropTypes.number,
  topupListInfo: PropTypes.object,
  updPhoneNumber: PropTypes.func,
  updateTopUpVal: PropTypes.func,
  updateTopUpMinVal: PropTypes.func,
  topupMinValuePreUpdate: PropTypes.number,
  sendTags: PropTypes.func,
  topupInfo: PropTypes.object,
  emergencyTopupLoanConverted: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopUp);
