import { name } from '../../package.json';
import { customerInfoPkgName } from '../appConstants';

export const CALL_TEALIUM = 'CALL_TEALIUM';
export const SET_PROPS = `SET_PROPS`;

export const sendTags = (taggingValue, taggingType) => ({
  type: CALL_TEALIUM,
  payload: {
    taggingValue: taggingValue,
    taggingType: taggingType,
  },
});
export const SET_SERVICES = `@${name}/SET_SERVICES`;

export const setServices = response => ({
  type: SET_SERVICES,
  payload: response
});


export const SET_ADDRESS_ID = `@${customerInfoPkgName}/SET_ADDRESS_ID`;

//Search for customers action creators
export const setAddressID = id => ({
  type:  SET_ADDRESS_ID ,
  selectedAddress: id
});

export const setProps = (comp, props) => ({
  type: SET_PROPS,
  payload: { comp, props },
});
