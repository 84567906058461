import { SET_ADDRESS_ID , setServices} from "../actions/Actions";
import {  getAllServices } from "../selectors/selector";
import {takeEvery , select, put } from 'redux-saga/effects';

function* filterProductAndService ({selectedAddress}) {
    try {
      const services = yield select(getAllServices);
      if(selectedAddress&&selectedAddress!="All"){
      const filteredServices =
      services.filter(e=> e.billingAccount.id === selectedAddress.toString());
      yield put(setServices(filteredServices));}
      else {
        yield put(setServices(services));}
    } catch (e) {
      console.log(e)
    }
}

export function* watchFilterProductAndService() {
    yield takeEvery(SET_ADDRESS_ID, filterProductAndService);
}

const watchers = [
  watchFilterProductAndService()
];

export default watchers;
