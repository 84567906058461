import initialState from './initialState';
import * as actions from '../actions/Actions'
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SERVICES: {
      return {
        ...state ,
        services: action.payload
      }
    }
    default:
      return state;
  }
};

export default appReducer;
