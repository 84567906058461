import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalContent } from 'r10-common-components';

import useTranslation from '../../hooks/useTranslation';
import {
  phoneNumberSelector,
  topUpValueSelector,
  paymentMethodSelector,
} from '../../selectors/selector';
import { updateCurrentScreen, topupRequest } from '../../actions/Actions';
import { SCREENS } from '../../appConstants';
import { sendTags } from './../../actions/Actions';

const TopUpConfirm = ({
  paymentMethod,
  updateCurrtScreen,
  dispatchTopupRequest,
  topUpAmount,
  currency,
  sendTags,
  emergencyTopupLoanConverted,
}) => {
  const t = useTranslation();
  const handleConfirm = useCallback(() => {
    dispatchTopupRequest();
  }, [dispatchTopupRequest]);

  const handleEdit = useCallback(() => {
    updateCurrtScreen(SCREENS.PAYMENT_METHOD_SELECTION);
  }, [updateCurrtScreen]);
  useEffect(() => {
    sendTags({
      taggingId: 'CUSTOMER_TOPUP_10',
      taggingType: 'view',
      taggingValue: {},
    });
  }, []);

  const subtitles = [
    {
      regular: t('confirmTransactionSubtitle'),
      marginBottom: 4,
    },
    {
      regular: `${t('topUpAmount')}: `,
      bold: `${topUpAmount}${currency || ''}`,
    },
    {
      regular: `${t('paymentMethod')}: `,
      bold: t(paymentMethod),
      marginBottom: 7,
    },
  ];

  if (emergencyTopupLoanConverted !== '0') {
    const loanSubtitle = {
      regular: `${t('loanConfirmation')} `,
      bold: `${emergencyTopupLoanConverted}${currency || ''}`,
    };
    subtitles.splice(2, 0, loanSubtitle);
  }

  return (
    <ModalContent
      header={t('headerTopUp')}
      icon="top-up"
      title={t('confirmTransactionTitle')}
      titleMarginBottom={0}
      subtitles={subtitles}
      primaryButton={{
        onClick: handleConfirm,
        text: t('confirmTransaction'),
        dataTestId: 'confirm-transaction-butto',
      }}
      secondaryButton={{
        onClick: handleEdit,
        text: t('backButton'),
        dataTestId: 'back-button',
      }}
    />
  );
};

const mapStateToProps = state => ({
  phoneNumber: phoneNumberSelector(state),
  topUpAmount: topUpValueSelector(state),
  // customerFullName: customerFullNameSelector(state),
  paymentMethod: paymentMethodSelector(state),
});
const mapDispatchToProps = dispatch => ({
  updateCurrtScreen: currentScreen => dispatch(updateCurrentScreen(currentScreen)),
  dispatchTopupRequest: () => dispatch(topupRequest()),
  sendTags: tagsObject => dispatch(sendTags(tagsObject)),
});
TopUpConfirm.propTypes = {
  updateCurrtScreen: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  dispatchTopupRequest: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  topUpAmount: PropTypes.number.isRequired,
  sendTags: PropTypes.func,
  emergencyTopupLoanConverted: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopUpConfirm);
