import { useSelector } from 'react-redux';
import { configSelector } from '../selectors/ConfigSelectors/ConfigSelectors';
import useProps from './useProps.js';
import get from 'lodash.get';

/**
 * @param {string} configPath the path for configurations
 * of the widget you want to get
 * @param {*} defaultValue the value that is returned
 * if it couldn't find the property using the path
 * @returns the widget configurations
 */
export default function useConfig(configPath, defaultValue) {
  const { __instanceId } = useProps();
  const config = useSelector(state => configSelector(state, __instanceId)) || {};
  return configPath ? get(config, configPath, defaultValue) || {} : config;
}
