import {
  pkgName,
  customerSearchName,
  customerInfoPkgName,
} from '../appConstants';

export const getServices = (state) => state[pkgName]?.services;

export const getAllServices = (state) =>
  state[customerSearchName]?.customerDetails[0]?.product;

export const getSearchType = (state) => state[customerSearchName]?.type;

export const getSearchValue = (state) => state[customerSearchName]?.value;

export const getSelectedAddressID = (state) =>
  state[customerInfoPkgName] && state[customerInfoPkgName]?.selectedAddressId;

export const getCustomerFullName = (state) =>
  state[customerSearchName]?.customerDetails[0]?.party?.fullName;

export const getTopupInfo = (state) => ({
  selectedBilling: getSelectedAddressID(state),
  customerFullName: getCustomerFullName(state),
  services: getServices(state),
});
