import { pkgName } from '../../appConstants';

export const configSelector = (state, instanceId) => {
  const myWidget = state.config?.data?.widgets[pkgName];

  if (myWidget) {
    return myWidget[instanceId];
  }

  return null;
};
